<template>
  <div>
    <div class="Section" v-wechat-title="this.clickVal+ '-药百万'">
      <div class="breadcrumbNav">
        <span class="navName">当前位置 : </span>
        <div class="activeNav">
          <span>
            <router-link to="/home"
            >{{ clickVal }} </router-link>
          </span>
        </div>
      </div>
      <ul class="filterUl">
        <li
          v-for="(item, index) in filterItems"
          :key="index"
          @click="filterFun(item,index)"
          :class="{ active: Boolean(filterType === index) }"
        >
          {{ item.sort }}
          <i
            :class="
              item.filterMark == 'points' ? Boolean(item.show === 1)?'active el-icon-caret-top  iconTop':'el-icon-caret-top  iconTop':Boolean(filterType === index)?'active el-icon-bottom':'el-icon-bottom'
            "
            aria-hidden="true"
          ></i>
           <i v-if="item.filterMark == 'points'"
            :class="Boolean(item.show === 2)?'active el-icon-caret-bottom iconBottom':'el-icon-caret-bottom iconBottom'"
            aria-hidden="true"
          ></i>
        </li>
        <li>
          <el-checkbox v-model="checked" @change="filterFun()"
            >仅显示推荐商品</el-checkbox
          >
        </li>
      </ul>
      <div class="goodsList" v-if="searchProducts!=[]">
        <div v-if="total==0">
          <p class="NullBox"></p>
        </div>
        <div v-else  v-infinite-scroll="load"   infinite-scroll-disabled="loadMore"  infinite-scroll-distance="2291">
           <IntegralMallItem  class="goods" v-for="(item,index) in searchProducts" :key="index" :info="item"></IntegralMallItem>
        </div>
      </div>
      <div class="goodsList" v-else>
         <p class="NullBox">敬请期待</p>
      </div>
      <!-- <div class="pageBox">
        <el-pagination
          background
          layout="prev, pager, next"
          :hide-on-single-page="false"
          prev-text="上一页"
          next-text="下一页"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="size"
          :total="total"
        >
        </el-pagination>
      </div> -->
    </div>
  </div>
</template>

<script>
const IntegralMallItem=()=>import('components/index/IntegralMallItem.vue');
import { IntegralMallList  } from "api/activity.js";
export default {
  name: "IntegralMallList",
  data() {
    return {
      clickVal: "药豆商城",
      total: 0,
      current: 1, //当前页
      size: 10, //每页数量
      searchProducts: [], //初始商品数据
      filterType:'',
      sort:0,//传给后台的搜索值
      filterItems: [
        // { sort: "销量", show: false, filterMark: "sale" },
        // { sort: "上架时间", show: false, filterMark: "createTime" },
        { sort: "药豆",show: 0, filterMark: "points" },
      ],
      parmesData: {}, //初始搜索值传参
       checked: false, //筛选checked 选中状态  初始为选中
       loadMore: false,
    };
  },
  computed: {
    disabled () {
        return this.loadMore 
      }
  },
  created() {
    // console.log([]===[]);
    this.changeType();
  },
  methods: {
    handleCurrentChange(val) {
      this.current = val;
       this.parmesData.status=1;//状态 0下架1上架
      this.parmesData.current = this.current; //当前页
      this.parmesData.size = this.size; //每页数量
       this.getIntegralMallList(this.parmesData);
    },
    changeType() {
      this.parmesData.status=1;//状态 0下架1上架
      this.parmesData.current = 1; //当前页
      this.parmesData.size = 10; //每页数量
      this.getIntegralMallList(this.parmesData)
      
    },


// 商城列表页接口
getIntegralMallList(datas){
     // 显示loading
      this.$loading({ fullscreen: true ,background:'#ffffff45'})
      IntegralMallList(datas).then(res=>{
        //console.log(res.data.data)
        const moreData = res.data.data.records;
        this.searchProducts =this.searchProducts.concat(moreData);
          this.total = Number(res.data.data.total);
          if(this.searchProducts.length){
            if(this.parmesData.current*this.parmesData.size>= this.total ){
            this.loadMore =true 
            }else{
            this.loadMore = false
            }
        }
            // 关闭loading
          this.$loading().close();
      })
},

load () {
  console.log('继续假装')
  // this.count += 2
  this.current += 1;//当前页
  this.parmesData.current = this.current; //当前页
  this.getIntegralMallList(this.parmesData)
},
    // 点击筛选条件，获取商品数据渲染
    filterFun(item,index) {
      if (item) {
          this.filterType=index
          if(this.filterType===0){
            // 药豆排序
            if(item.show===1){
              item.show = 2;
              this.parmesData.descs = '';
               this.parmesData.ascs = 'points';
            }else{
              item.show = 1;
               this.parmesData.ascs = '';
              this.parmesData.descs = 'points';
            }
          }
          this.searchProducts=[]
          this.current=1
          this.parmesData.current = 1; //当前页
            this.getIntegralMallList(this.parmesData);
      }else {
        //显示推荐商品 isRecommend_equal   0-> 否,1->是 ", paramType = "query", dataType = "integer"
        if (this.checked == false) {
          this.parmesData.isRecommend_equal = ''; //否
          this.searchProducts=[]
          this.current=1
          this.parmesData.current = 1; //当前页
            this.getIntegralMallList(this.parmesData);
        } else {
          //显示推荐商品
           this.parmesData.isRecommend_equal = 1; //是
           this.searchProducts=[]
          this.current=1
          this.parmesData.current = 1; //当前页
            this.getIntegralMallList(this.parmesData);
        }
      }
    },
  },
  watch: {
    "$route.query": "changeType",
  },
  components: {
    IntegralMallItem
  },

  mounted() {},
  updated() {},
};
</script>
<style>
::-webkit-scrollbar  
{  
    width: 5px;  
}
  
/*定义滚动条轨道 内阴影+圆角*/  
::-webkit-scrollbar-track  
{  
    border-radius: 10px;  
    background-color: rgba(0,0,0,0.1); 
} 
  
/*定义滑块 内阴影+圆角*/  
::-webkit-scrollbar-thumb  
{  
    border-radius: 10px;  
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);  
    background-color: rgba(0,0,0,0.1);
} 
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ed3129;
  color: #fff;
}
/* 上一页，下一页样式 */
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  background: #fff;
  color: #666;
  padding: 2px;
  box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li {
  background: #fff;
  color: #666;
  padding: px;
  box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
  color: #ed3129;
  font-size: 14px;
}
</style>
<style lang="less" scoped>
.pageBox {
  float: left;
  width: 100%;
  .el-pagination {
    padding-top: 40px;
    text-align: center;
    float: right;
  }
}

@import "~style/index.less";
/* 隐藏未编译的变量 */
[v-cloak] {
  display: none;
}
.Section {
    width: 1200px;
     margin: 10px auto 0;
  // float: left;
  // margin-top: 10px;
  background: #f6f6f6;
  padding-bottom: 40px;
  overflow: hidden;
  .breadcrumbNav {
    padding: 8px 15px 15px 0;
    margin-bottom: 17px;
    font-size: 13px;
    line-height: 20px;
    .navName {
      float: left;
      color: #999;
      padding-right: 8px;
    }
    .activeNav {
      float: left;
      color: #999;
      line-height: 20px;
      span {
        color: #551a8b;
        padding-right: 8px;
        float: left;
      }
      b {
        float: left;
      }
      .checkUl {
        float: left;
        padding-left: 8px;
        li {
          padding: 0 5px;
          height: 20px;
          line-height: 18px;
          border: 1px solid #ff9999;
          color: #ff9999;
          font-size: 12px;
          background: #fff;
          margin-right: 8px;
          float: left;
        }
      }
    }
  }
  .sortBox {
    box-sizing: content-box;
    background-color: #ffffff;
    margin-bottom: 17px;
    border: 1px solid #eeeeee;
    width: 100%;
    float: left;
    .sortUlbox:last-child {
      border-bottom: none;
    }
    .sortUlbox {
      width: 100%;
      padding: 8px 15px;
      float: left;
      line-height: 40px;
      border-bottom: 1px dashed #ccc;
      .ulTitle {
        font-size: 14px;
        font-weight: bold;
        color: #666666;
        width: 136px;
        text-align: center;
        float: left;
      }
      .sortUl {
        width: calc(100% - 136px);
        float: left;
        li {
          float: left;
          margin-right: 40px;
          color: #666;
          font-size: 14px;
            cursor: pointer;
            &:hover{
                color: @theme;
            }
        }
        li.active {
          color: @theme;
        }
      }
    }
  }

  .filterUl {
    padding: 8px 15px;
    margin-bottom: 17px;
    border: 1px solid #eeeeee;
    box-sizing: content-box;
    background-color: #ffffff;
    height: 20px;
    line-height: 20px;
    width: 100%;
    float: left;
    li.active {
      color: #ff9999;
    }
    li {
      float: left;
      margin-right: 40px;
      color: #666666;
      font-size: 12px;
      position: relative;
        cursor: pointer;
            &:hover{
                color: @theme;
                .el-checkbox{
                  color: @theme;
                }
               
            }
      i {
        font-weight: bolder;
        font-size: 14px;
         color: #666666;
        &.active{
           color: @theme;
        }
        &.iconTop{
            position:absolute;
            right: -18px;
            top: 0;
        }
        &.iconBottom{
            position:absolute;
            right: -18px;
            bottom: 0;
        }
      }
    }
  }
  .goodsList {
    height: auto;
    // overflow: hidden;
    float: left;
    min-height: 228px;
    background: #f6f6f6;
    width: 100%;
    .goods:nth-child(5n + 0) {
      margin-right: 0px;
    }
    .NullBox{
      font-size: 30px;
      text-align: center;
      width: 100%;
      line-height: 300px;
    height: 300px;
      background:url("data:image/svg+xml,%3Csvg width='130' height='130' xmlns='http://www.w3.org/2000/svg'%3E%3Ctext x='50%25' y='50%25' font-size='14' fill='%23a2a9b6' font-family='system-ui, sans-serif' text-anchor='middle' transform='rotate(-45, 100 100)' dominant-baseline='middle'%3E药百万%3C/text%3E%3C/svg%3E");
      }
  }
}
</style>
